import { render } from "./Pr365Low.vue?vue&type=template&id=76ac9e07&scoped=true"
import script from "./Pr365Low.vue?vue&type=script&lang=ts"
export * from "./Pr365Low.vue?vue&type=script&lang=ts"

import "../../assets/newPromotions.scss?vue&type=style&index=0&id=76ac9e07&lang=scss&scoped=true"
import "./Pr365Low.vue?vue&type=style&index=1&id=76ac9e07&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-76ac9e07"
/* hot reload */
if (module.hot) {
  script.__hmrId = "76ac9e07"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('76ac9e07', script)) {
    api.reload('76ac9e07', script)
  }
  
  module.hot.accept("./Pr365Low.vue?vue&type=template&id=76ac9e07&scoped=true", () => {
    api.rerender('76ac9e07', render)
  })

}

script.__file = "src/page/promotion/Pr365Low.vue"

export default script