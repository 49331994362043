
import { AxiosResponse } from 'axios';
import vClickOutside from 'click-outside-vue3';
import { storage, native } from '@/tools';
import api from '@/common/api';
import inappTools from '@/common/inappTools';
import tools from '@/common/tools';
import vImageLazyLoading from '../../common/directives/ImgLazyLoading';
import promotionMixins from '../../common/mixins/promotion';
import AppNav from '../../components/AppNav.vue';
import PopupToast from '../../components/PopupToast.vue';

import 'swiper/swiper-bundle.css';

export default {
  name: 'Pr365Low',
  components: {
    AppNav,
    PopupToast,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  mixins: [promotionMixins],
  data() {
    return {
      imgUrls: {
        top_title: '//res.tutoring.co.kr/res/images/evt/202211/bigseason/top_title_2.png',
        top_title_new: '//res.tutoring.co.kr/res/images/evt/202312/bigseason/top_title.png',

        togo_title01: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_title01.png',

        togo_price01: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_price01.png',
        togo_price02: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_price02.png',
        togo_price03: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_price03.png',
        togo_price04: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_price04.png',

        big_receipt: '//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_02.png',
        big_receipt_new: '//res.tutoring.co.kr/res/images/evt/202406/big_receipt.png',

        goods1: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_03-1.png',
        goods2: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_03-2.png',

        event: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_event_completed.png',
        event3: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_event_completed2.png',

        togo_02_2: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_02-2.png',

        big_tit_03: '//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_tit_03.png',

        big_coupon: '//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon.png',

        togo_title04: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_title04.png',
        togo_04_1: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-1.png',
        togo_04_2: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-2.png',
        togo_04_3: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-3.png',
        togo_04_4: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-4.png',
        togo_04_5: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-5.png',
        togo_04_6: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-6.png',
        togo_04_7: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-7.png',

        pt90_mid_09: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_10.png',

        togo_title06: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_title06.png',

        festival_03: '//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_03.png',
      },
      promotionData: { plans: [{ cp_idx: 0 }] },

      isPopup: false,
      cuponPopupMessage: '쿠폰 다운로드 완료!',

      isSampleClicked: false,

      purchaseErrorMessage: '',
      purchaseErrorSubMessage: '',
      purchaseErrorType: '',
      // 사용자가 선택한 플랜의 idx
      choicedOptionIdx: 5969 as Number,

      // 사용자가 선택한 플랜 데이터 저장 _ 옵션 상세 부분 및 구매 이동에서 사용
      choicedOptionData: {},

      curTab: 1,
      tabsFix: false,
      tabsFixTop: '0px',
      bottomCtaUp: false,

      counter: {
        word: 0,
        sentence: 0,
        time: 0,
      },

      isMobileBottomSheetOpen: false,

      thisWeekStart: '',
      thisWeekEnd: '',
      nextWeekStart: '',

      timestampEnd: new Date('29 Sep 2024 23:59:59').getTime(),
      timestampFinal: new Date('29 Sep 2024 23:59:59').getTime(),

      countdownDate: new Date('29 Sep 2024 23:59:59').getTime(),
      nowDate: new Date().getTime(),

      hasCouponIdx: [],
      allCouponIdx: [],
    };
  },

  created() {
    this.getPromotionData();
  },
  mounted() {
    // if (!inappTools.isInApp()) {
    // 	this.$router.go(-1);
    // }
    // this.goHome();
    this.onBottomSheetOn();
    this.onTabFixHandlerOn();
    this.initBackToPromotionFromCounselingFlag();
    this.setPurchaeDateInfo();
    // window.C.NativeTool.onBackPressed = () => {
    // 	native.close();
    // };
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },

  methods: {
    openSample(): void {
      this.isSampleClicked = !this.isSampleClicked;
    },
    goToForm() {
      window.location.href = `${window.location.protocol}//${window.location.host}/v2/classApply`;
      // window.open("https://tutoring24.typeform.com/to/FDSQq9GR", "_self");
    },
    closePurchaseModal(): void {
      if (inappTools.isInApp()) {
        native.close();
      } else {
        this.$router.push('/');
      }
    },

    async getPromotionData(): Promise<any> {
      this.promotionData = await api
        .get('/app/etc/promotion/coupons?promotion=festival')
        .then((response: AxiosResponse) => response.data);

      console.log('this PROMOTION DATA ??', this.promotionData);

      this.promotionData.coupon.data.coupons = this.promotionData.coupon.data.coupons.reverse();
      this.promotionData.coupon.data.coupons.forEach((coupon) => {
        this.allCouponIdx.push(coupon.idx);
        if (coupon.hasCoupon) {
          this.hasCouponIdx.push(coupon.idx);
        }
      });
      // this.choicedOptionIdx = this.promotionData.plan[1].cp_idx;
      // this.choicedOptionData = { ...this.promotionData.plan[1] };
    },

    async postCouponDownload(dcIdx: number): Promise<any> {
      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        await api
          .post(`/app/users/coupon/discount/${dcIdx}`, {})
          .then((response) => {
            console.log('response', response);
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(dcIdx);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              // this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              // this.clickPopupButton();
              console.log('postCouponDownload fail dcIdx:', dcIdx, 'res:', response);
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },

    async postCouponDownloadAll(): Promise<any> {
      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        await api
          .post(`/app/users/coupon/download/all`, { couponIdx: this.allCouponIdx.toString() })
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(...this.allCouponIdx);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              // this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              // this.clickPopupButton();
              console.log('postCouponDownloadAll fail', response);
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },

    clickPopupButton() {
      this.isPopup = true;
      setTimeout(() => {
        this.isPopup = false;
      }, 2000);
    },
    goHome() {
      if (inappTools.isInApp()) {
        storage.session.set('routeAfterWebViewClosed', '/app/home/lms');
        native.close();
      }
      if (!inappTools.isInApp()) {
        window.location.href = `${window.location.protocol}/v2`;
      }
    },

    goPrice() {
      // const selectedPlan = this.promotionData.plan.filter((plan) => plan.cp_idx === cp_idx)[0];
      // storage.session.set('goodsForOrder', selectedPlan);
      if (inappTools.isInApp()) {
        native.close();
      } else {
        this.pushToLogin();
        window.location.href = `${window.location.protocol}/v2/plan/all`;
        // const url = `https://${document.location.host}/home/order?&ptype=Kr`;
        // window.location.href = url;
      }
    },

    goPr90days() {
      window.location.href = `${window.location.protocol}/v2/promotions/pr90days`;
      // https://app.tutoring.co.kr/v2/promotions/pr90days  작심 90일 상세 페이지
    },

    goPr90dayMiddle() {
      window.location.href = `${window.location.protocol}/v2/promotions/pr90dayMiddle`;
    },

    goMoreTicket() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        storage.session.set('routeAfterWebViewClosed', '/app/home/drawer/purchaseMain?tab=basic&isWebView=');
        native.close();
      } else {
        // 웹일 때
        window.location.href = `${window.location.protocol}/v2/plan/all`;
      }
    },

    buyCourse() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        storage.session.set('routeAfterWebViewClosed', '/app/home/ticket/select/option');
        native.close();
      } else {
        // 웹일 때
        window.location.href = `${window.location.protocol}/v2/plan/all/pass`; // 수강신청 메뉴
      }
    },

    openBottomSheetTab() {
      this.isMobileBottomSheetOpen = !this.isMobileBottomSheetOpen;
    },
    closeBottomSheetTab() {
      this.isMobileBottomSheetOpen = !this.isMobileBottomSheetOpen;
    },

    updateOptionIdx(Id) {
      this.choicedOptionIdx = Id;
    },

    pushToLogin() {
      if (!tools.isLoggedIn()) {
        window.location.href = '/home/login';
      }
    },

    goPurchasePage() {
      const selectedPlan = this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0];
      storage.session.set('goodsForOrder', selectedPlan);
      if (inappTools.isInApp()) {
        native.close();
      } else {
        const url = `https://${document.location.host}/home/order?&ptype=Kr&courseIdx=undefined`;
        window.location.href = url;
      }
    },
    purchasePlan() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        // storage.session.set('routeAfterWebViewClosed', '/app/home/ticket/select/option');
        storage.session.set('routeAfterWebViewClosed', '/app/home/drawer/purchaseMain?tab=basic&isWebView=');
        native.close();
      } else {
        // 웹일 때
        const url = `https://${document.location.host}/v2/plan/all`; // 수강신청 메뉴
        window.location.href = url;
      }
    },
    initBackToPromotionFromCounselingFlag() {
      // 페이지 마운트 되었을 때 프로모션에서 상담예약으로 넘어갔는지 여부를 지워버리는 함수.
      // 만약 이전에 스크롤된 기록이 있다면, 그 위치로 스크롤 넘겨버림.
      const lastScrollOffset = storage.session.get('pr90DaysLastScrollOffset');
      if (lastScrollOffset) {
        setTimeout(() => {
          window.scrollTo(0, lastScrollOffset);
        });
      }
      storage.session.remove('backToPromotionFromCounselingFlag');
      storage.session.remove('pr90DaysLastScrollOffset');
    },

    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          this.bottomCtaUp = true;
        } else {
          this.bottomCtaUp = false;
        }
      });
    },

    onChangeTab(idx) {
      this.curTab = idx;
    },
    onTabFixHandlerOn() {
      window.addEventListener('scroll', () => {
        const scrollLocation = document.documentElement.scrollTop;
        const startOffset = document.getElementById('togo1')?.offsetTop;
        // const endOffset = document.getElementById("differ")?.offsetTop;

        if (startOffset) {
          if (inappTools.isInApp()) {
            if (scrollLocation > startOffset - 95) {
              this.tabsFix = true;
              this.tabsFixTop = '48px';
            } else {
              this.tabsFix = false;
            }
          }
          // 웹
          else if (window.innerWidth > 1199) {
            if (scrollLocation > startOffset - 150) {
              this.tabsFix = true;
              this.tabsFixTop = '136px';
            } else {
              this.tabsFix = false;
            }
          }
          // 웹 이하
          else if (scrollLocation > startOffset - 130) {
            this.tabsFix = true;
            this.tabsFixTop = '38px';
          } else {
            this.tabsFix = false;
          }
        }

        const togo1 = document.getElementById('togo1')?.offsetTop;
        const togo2 = document.getElementById('togo2')?.offsetTop;
        const togo3 = document.getElementById('togo3')?.offsetTop;
        const togo4 = document.getElementById('togo4')?.offsetTop;

        let differ = 65;
        if (window.innerWidth > 1199) {
          differ = 140;
        }

        if (togo1 && togo2 && togo3 && togo4) {
          if (togo1 <= scrollLocation + differ && scrollLocation + differ < togo2) {
            this.curTab = 1;
          } else if (togo2 <= scrollLocation + differ && scrollLocation + differ < togo3) {
            this.curTab = 2;
          } else if (togo3 <= scrollLocation + differ && scrollLocation + differ < togo4) {
            this.curTab = 3;
          } else {
            this.curTab = 0;
          }
        }
      });
    },
    guaranteeSectionOffset() {
      if (window.innerWidth > 1199) {
        return 195;
      }
      return 95;
    },
    courseTabChoiceSectionOffset() {
      if (window.innerWidth > 1199) {
        return 140;
      }
      return 65;
    },
    setPurchaeDateInfo() {
      const curDay = new Date();
      const curYear = curDay.getFullYear();
      const curMonth = curDay.getMonth();
      const curDate = curDay.getDate();
      const curDayofWeek = curDay.getDay();
      const weekStartDate = new Date(curYear, curMonth, curDate - curDayofWeek + 1);
      const weekEndDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 1);
      const nextWeekStartDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 2);
      this.thisWeekStart = `${weekStartDate.getMonth() + 1}/${weekStartDate.getDate()}`;
      this.thisWeekEnd = `${weekEndDate.getMonth() + 1}/${weekEndDate.getDate()}`;
      this.nextWeekStart = `${nextWeekStartDate.getMonth() + 1}/${nextWeekStartDate.getDate()}`;
    },
    showCountdown() {
      return this.nowDate < this.countdownDate;
    },
  },

  computed: {
    hasCouponIdxArray() {
      return (idx) => this.hasCouponIdx.includes(idx);
    },
    hasCouponDownloadAll() {
      return this.hasCouponIdx.length < 4;
    },
    sortNoticeText() {
      return this.promotionData.planGuide.map((data) => data.contents.map((e) => e.text.replace(/\n|n/g, '<br />')));
    },
    appView() {
      return inappTools.isInApp();
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '48px';
      }
      return '0px';
    },
    appBottom() {
      if (inappTools.isInApp()) {
        return '100px';
      }
      return '0px';
    },
    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
  },

  watch: {
    // 옵션 상세 영역에 뿌려줄 선택한 데이터 가공
    choicedOptionIdx() {
      this.choicedOptionData = {
        ...this.promotionData.plan.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0],
      };
    },
  },
};
