<template>
  <!-- app nav -->
  <AppNav v-if="appView">링고라 페스티벌</AppNav>

  <div class="promotion" :style="{ paddingTop: appPadding, paddingBottom: appBottom }">
    <!-- tutoring-festival -->
    <section class="main">
      <div class="background_snow">
        <div class="tudal"></div>
        <div class="benefit_container">
          <div class="benefit_box">
            <div class="title">
              2024년 12월 한정 혜택
              <br />
              지금 바로 만나보세요!
              <h5 class="event_date">이벤트 기간: ~2024년 12월 31일</h5>
            </div>
            <div class="benefit">
              <div class="benefit_text_wrap">
                <div class="benefit_label">혜택 1</div>
                <div class="benefit_text">
                  할인 쿠폰팩 총
                  <span class="emphasis_text">45만원</span>
                </div>
              </div>
              <div class="benefit_text_wrap">
                <div class="benefit_label">혜택 2</div>
                <div class="benefit_text">
                  수강권 최대
                  <span class="emphasis_text">72%</span>
                  할인
                </div>
              </div>
              <div class="benefit_text_wrap end_text">
                <div class="benefit_label">혜택 3</div>
                <div class="benefit_text">
                  원어민회화 수강권 구매 시
                  <br />
                  <span class="emphasis_text">AI 학습 무료 제공</span>
                </div>
              </div>
            </div>

            <button
              class="button"
              :class="[{ downloadAllAfter: !hasCouponDownloadAll }]"
              :disabled="!hasCouponDownloadAll"
              @click="postCouponDownloadAll()"
            >
              쿠폰팩 모두 받기
            </button>
          </div>
        </div>
        <div class="tudal_2"></div>
      </div>
    </section>

    <section class="course_container">
      <div class="coupon__container" id="togo1">
        <div class="label">혜택 1</div>
        <div class="benefit__desc" style="margin-bottom: 54px">
          바로 사용 가능한
          <br />
          할인 쿠폰 총 45만원
        </div>

        <div
          v-for="(coupon, index) in promotionData?.coupon?.data?.coupons"
          :key="index"
          @click="!hasCouponIdxArray(coupon.idx) && !coupon.hasCoupon && postCouponDownload(coupon.idx)"
          :class="[
            { 'coupon coupon200000': coupon.dc_money === 200000 },
            { 'coupon coupon100000': coupon.dc_money === 100000 },
            { 'coupon coupon70000': coupon.dc_money === 70000 },
            { 'coupon coupon50000': coupon.dc_money === 50000 },
            { 'coupon coupon30000': coupon.dc_money === 30000 },
            { 'is-download': coupon.hasCoupon || hasCouponIdxArray(coupon.idx) },
          ]"
        ></div>
        <button
          :disabled="!hasCouponDownloadAll"
          @click="postCouponDownloadAll()"
          :class="[{ downloadAllBefore: hasCouponDownloadAll }, { downloadAllAfter: !hasCouponDownloadAll }]"
        >
          쿠폰 한 번에 받기
        </button>
      </div>
      <div class="coupon__info">
        <div class="inner">
          <p class="title">쿠폰 유의 사항</p>
          <div class="desc">
            <div class="list__item__text">• 쿠폰 다운로드 후 사용 가능</div>
            <div class="list__item__text">• 올패스 정기권, 알뜰플랜, AI+ 구매 시 사용 불가</div>
            <div class="list__item__text">• 더하기 쿠폰 중복 사용 가능</div>
            <div class="list__item__text">• 365 올패스 재구매 할인 적용 시에도 쿠폰 사용 가능</div>
          </div>
        </div>
      </div>

      <div class="course__info" style="padding-bottom: 40px; padding-top: 38px">
        <div class="inner" id="togo2">
          <div class="label">혜택 2</div>
          <div class="benefit__desc" style="margin-bottom: 20px">
            수강권
            <br />
            최대 72% 할인
          </div>

          <img v-img-lazy-loading :data-url="imgUrls.big_receipt" class="big_receipt" />
          <button class="buyCourse" @click="goMoreTicket">수강권 둘러보기</button>
        </div>
      </div>

      <div class="divider"></div>

      <div class="course__info" style="padding-bottom: 27px; padding-top: 37px">
        <div class="inner" id="togo3">
          <div class="label">혜택 3</div>
          <div class="benefit__desc" style="margin-bottom: 12px">
            원어민 회화 수강권 구매 시,
            <br />
            AI 학습 100% 무료 제공
          </div>

          <img v-img-lazy-loading :data-url="imgUrls.festival_03" class="festival_03" />
          <button class="buyCourse" @click="goMoreTicket">수강권 둘러보기</button>
        </div>
      </div>
    </section>

    <!-- 수강증 발급 안내 -->
    <section class="focus-certificate">
      <div class="certificate-sector">
        <div class="promotion-certificate">
          <h2 class="heading">수강증 상시 발급 가능</h2>
          <p class="description" style="margin-top: 30px">수강증, 출석확인증, 영수증 상시 발급 가능</p>
          <div class="certificate__tip" style="margin-top: 10px">
            직장인들을 위한 SPEC UP!
            <br />
            사내 교육지원비로 알뜰하게!
          </div>
          <button @click="openSample" type="button" class="sample_btn" style="margin-top: 10px">
            <p>증빙서류 샘플 상세보기</p>
            <div class="rArrow"></div>
          </button>
        </div>
      </div>
      <div class="promotion-guide">
        <h2 class="heading">증빙 서류 발급 안내</h2>
        <ul class="guide__list">
          <li class="list__item">
            수강증, 출석 확인증, 영수증은 링고라 앱 메인 화면 우측 하단 [마이링고라] &gt; [고객센터] &gt; [증빙 서류
            발급 메뉴]에서 신청 가능합니다.
          </li>
          <li class="list__item">정해진 학습 기간 내에 수업을 진행해야만 출석으로 인정합니다.</li>
          <li class="list__item">증빙서류의 경우 내용(수강권명, 금액)등은 임의 수정이 불가합니다.</li>
          <li class="list__item">
            발급 신청 시 입력한 이메일 주소로 자동 발급됩니다.(기타 문의사항은 [고객센터 &gt; 1:1 채팅 문의]로 연락
            주시기 바랍니다.)
          </li>
        </ul>
      </div>
    </section>

    <!-- 증명서류 샘플 상세보기 클릭시 나오는 부분 -->

    <div
      v-if="isSampleClicked"
      class="common-modal common-modal--dimmed"
      id="modalCertificateClose"
      style="display: block"
    >
      <div class="common-modal__modal modal__certificate" v-click-outside="openSample">
        <div class="common-modal__content">
          <h1 class="heading">증빙서류 샘플</h1>
          <p class="description">
            수강증, 출석확인증, 영수증 상시 발급 가능
            <br />
            연 수강권의 경우 매월 수강권 발급이 가능합니다.
          </p>
          <ul class="list">
            <li class="list__item">
              <img width="270" src="//res.tutoring.co.kr/res/images/evt/com/receipt_img01.png" alt="" />
              - 수강증 증빙서류 샘플 -
            </li>
            <li class="list__item">
              <img width="270" src="//res.tutoring.co.kr/res/images/evt/com/receipt_img02.png" alt="" />
              - 출석 확인증 증빙서류 샘플 -
            </li>
            <li class="list__item">
              <img width="270" src="//res.tutoring.co.kr/res/images/evt/com/receipt_img03.png" alt="" />
              - 영수증 증빙서류 샘플 -
            </li>
          </ul>
        </div>
        <button type="button" class="modal__certificate__close" @click="openSample">닫기</button>
      </div>
    </div>

    <!-- 하단 유의 사항  -->
    <section class="promotion-notice">
      <div class="notice__item" style="padding: 0">
        <h2 class="heading">
          <button type="button" class="heading__button">링고라 유의사항 안내</button>
        </h2>
        <div class="contents-center">
          <div class="promotion_contents">
            <div class="content">
              <div class="title">[이벤트 유의 사항 및 쿠폰 사용 안내]</div>
              <ul class="content__list">
                <li class="list__item">
                  본 이벤트는 당사 사정에 따라 예고없이 일정이 변경되거나 이벤트가 중단될 수 있습니다.
                </li>
                <li class="list__item">
                  <strong>쿠폰 할인 적용을 원하실 경우, 페이지 상단에서 원하시는 쿠폰을 다운로드해 주세요!</strong>
                </li>
                <li class="list__item">
                  본 이벤트 쿠폰은 링고라에서 판매 중인 수강권 구매 시 택1 사용 가능하며, 판매가 기준 30만원 미만인
                  수강권은 쿠폰 사용이 불가합니다.
                </li>
                <li class="list__item">본 이벤트 쿠폰은 다운로드 후 3일간 사용 가능합니다.</li>
                <li class="list__item">다운로드 완료된 쿠폰은 [마이링고라 &gt; 나의 쿠폰]에서 확인하실 수 있습니다.</li>
                <li class="list__item">
                  구매하시는 수강권의 사용 방법 및 환불 정책은 각 수강권의 유의사항을 확인해주시기 바랍니다.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="challenge-cta is-info" id="challengeCta">
      <!-- l-select 에 is-on 넣을때 얘도 같이 is-on 넣어주세요  -->
      <div class="dim"></div>
      <div class="challenge-cta__wrap">
        <div class="wrapper">
          <div class="btnwrap">
            <button type="button" @click="purchasePlan()" class="cta__button cta__button pc-layer pc goToPay">
              <span class="btnText">최대 72% 할인받기</span>
              <div class="rightArrow"></div>
            </button>
            <button
              type="button"
              class="cta__mobile cta__button tamo-layer tablet-mobile"
              data-status="N"
              @click="purchasePlan()"
            >
              <span class="btnText">최대 72% 할인받기</span>
              <div class="rightArrow"></div>
            </button>
          </div>
        </div>
      </div>
    </section>

    <PopupToast :isPopup="isPopup"><p v-html="cuponPopupMessage"></p></PopupToast>
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import vClickOutside from 'click-outside-vue3';
import { storage, native } from '@/tools';
import api from '@/common/api';
import inappTools from '@/common/inappTools';
import tools from '@/common/tools';
import vImageLazyLoading from '../../common/directives/ImgLazyLoading';
import promotionMixins from '../../common/mixins/promotion';
import AppNav from '../../components/AppNav.vue';
import PopupToast from '../../components/PopupToast.vue';

import 'swiper/swiper-bundle.css';

export default {
  name: 'Pr365Low',
  components: {
    AppNav,
    PopupToast,
  },
  directives: {
    clickOutside: vClickOutside.directive,
    'img-lazy-loading': vImageLazyLoading,
  },
  mixins: [promotionMixins],
  data() {
    return {
      imgUrls: {
        top_title: '//res.tutoring.co.kr/res/images/evt/202211/bigseason/top_title_2.png',
        top_title_new: '//res.tutoring.co.kr/res/images/evt/202312/bigseason/top_title.png',

        togo_title01: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_title01.png',

        togo_price01: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_price01.png',
        togo_price02: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_price02.png',
        togo_price03: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_price03.png',
        togo_price04: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_price04.png',

        big_receipt: '//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_02.png',
        big_receipt_new: '//res.tutoring.co.kr/res/images/evt/202406/big_receipt.png',

        goods1: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_03-1.png',
        goods2: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_03-2.png',

        event: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_event_completed.png',
        event3: '//res.tutoring.co.kr/res/images/evt/202306/2023festival/festival_event_completed2.png',

        togo_02_2: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_02-2.png',

        big_tit_03: '//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_tit_03.png',

        big_coupon: '//res.tutoring.co.kr/res/images/evt/202211/bigseason/big_coupon.png',

        togo_title04: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_title04.png',
        togo_04_1: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-1.png',
        togo_04_2: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-2.png',
        togo_04_3: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-3.png',
        togo_04_4: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-4.png',
        togo_04_5: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-5.png',
        togo_04_6: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-6.png',
        togo_04_7: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_04-7.png',

        pt90_mid_09: '//res.tutoring.co.kr/res/images/evt/202203/pt90/pt90_10.png',

        togo_title06: '//res.tutoring.co.kr/res/images/evt/202206/readytogo/togo_title06.png',

        festival_03: '//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_03.png',
      },
      promotionData: { plans: [{ cp_idx: 0 }] },

      isPopup: false,
      cuponPopupMessage: '쿠폰 다운로드 완료!',

      isSampleClicked: false,

      purchaseErrorMessage: '',
      purchaseErrorSubMessage: '',
      purchaseErrorType: '',
      // 사용자가 선택한 플랜의 idx
      choicedOptionIdx: 5969 as Number,

      // 사용자가 선택한 플랜 데이터 저장 _ 옵션 상세 부분 및 구매 이동에서 사용
      choicedOptionData: {},

      curTab: 1,
      tabsFix: false,
      tabsFixTop: '0px',
      bottomCtaUp: false,

      counter: {
        word: 0,
        sentence: 0,
        time: 0,
      },

      isMobileBottomSheetOpen: false,

      thisWeekStart: '',
      thisWeekEnd: '',
      nextWeekStart: '',

      timestampEnd: new Date('29 Sep 2024 23:59:59').getTime(),
      timestampFinal: new Date('29 Sep 2024 23:59:59').getTime(),

      countdownDate: new Date('29 Sep 2024 23:59:59').getTime(),
      nowDate: new Date().getTime(),

      hasCouponIdx: [],
      allCouponIdx: [],
    };
  },

  created() {
    this.getPromotionData();
  },
  mounted() {
    // if (!inappTools.isInApp()) {
    // 	this.$router.go(-1);
    // }
    // this.goHome();
    this.onBottomSheetOn();
    this.onTabFixHandlerOn();
    this.initBackToPromotionFromCounselingFlag();
    this.setPurchaeDateInfo();
    // window.C.NativeTool.onBackPressed = () => {
    // 	native.close();
    // };
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },

  methods: {
    openSample(): void {
      this.isSampleClicked = !this.isSampleClicked;
    },
    goToForm() {
      window.location.href = `${window.location.protocol}//${window.location.host}/v2/classApply`;
      // window.open("https://tutoring24.typeform.com/to/FDSQq9GR", "_self");
    },
    closePurchaseModal(): void {
      if (inappTools.isInApp()) {
        native.close();
      } else {
        this.$router.push('/');
      }
    },

    async getPromotionData(): Promise<any> {
      this.promotionData = await api
        .get('/app/etc/promotion/coupons?promotion=festival')
        .then((response: AxiosResponse) => response.data);

      console.log('this PROMOTION DATA ??', this.promotionData);

      this.promotionData.coupon.data.coupons = this.promotionData.coupon.data.coupons.reverse();
      this.promotionData.coupon.data.coupons.forEach((coupon) => {
        this.allCouponIdx.push(coupon.idx);
        if (coupon.hasCoupon) {
          this.hasCouponIdx.push(coupon.idx);
        }
      });
      // this.choicedOptionIdx = this.promotionData.plan[1].cp_idx;
      // this.choicedOptionData = { ...this.promotionData.plan[1] };
    },

    async postCouponDownload(dcIdx: number): Promise<any> {
      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        await api
          .post(`/app/users/coupon/discount/${dcIdx}`, {})
          .then((response) => {
            console.log('response', response);
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(dcIdx);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              // this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              // this.clickPopupButton();
              console.log('postCouponDownload fail dcIdx:', dcIdx, 'res:', response);
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },

    async postCouponDownloadAll(): Promise<any> {
      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        await api
          .post(`/app/users/coupon/download/all`, { couponIdx: this.allCouponIdx.toString() })
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(...this.allCouponIdx);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              // this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              // this.clickPopupButton();
              console.log('postCouponDownloadAll fail', response);
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },

    clickPopupButton() {
      this.isPopup = true;
      setTimeout(() => {
        this.isPopup = false;
      }, 2000);
    },
    goHome() {
      if (inappTools.isInApp()) {
        storage.session.set('routeAfterWebViewClosed', '/app/home/lms');
        native.close();
      }
      if (!inappTools.isInApp()) {
        window.location.href = `${window.location.protocol}/v2`;
      }
    },

    goPrice() {
      // const selectedPlan = this.promotionData.plan.filter((plan) => plan.cp_idx === cp_idx)[0];
      // storage.session.set('goodsForOrder', selectedPlan);
      if (inappTools.isInApp()) {
        native.close();
      } else {
        this.pushToLogin();
        window.location.href = `${window.location.protocol}/v2/plan/all`;
        // const url = `https://${document.location.host}/home/order?&ptype=Kr`;
        // window.location.href = url;
      }
    },

    goPr90days() {
      window.location.href = `${window.location.protocol}/v2/promotions/pr90days`;
      // https://app.tutoring.co.kr/v2/promotions/pr90days  작심 90일 상세 페이지
    },

    goPr90dayMiddle() {
      window.location.href = `${window.location.protocol}/v2/promotions/pr90dayMiddle`;
    },

    goMoreTicket() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        storage.session.set('routeAfterWebViewClosed', '/app/home/drawer/purchaseMain?tab=basic&isWebView=');
        native.close();
      } else {
        // 웹일 때
        window.location.href = `${window.location.protocol}/v2/plan/all`;
      }
    },

    buyCourse() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        storage.session.set('routeAfterWebViewClosed', '/app/home/ticket/select/option');
        native.close();
      } else {
        // 웹일 때
        window.location.href = `${window.location.protocol}/v2/plan/all/pass`; // 수강신청 메뉴
      }
    },

    openBottomSheetTab() {
      this.isMobileBottomSheetOpen = !this.isMobileBottomSheetOpen;
    },
    closeBottomSheetTab() {
      this.isMobileBottomSheetOpen = !this.isMobileBottomSheetOpen;
    },

    updateOptionIdx(Id) {
      this.choicedOptionIdx = Id;
    },

    pushToLogin() {
      if (!tools.isLoggedIn()) {
        window.location.href = '/home/login';
      }
    },

    goPurchasePage() {
      const selectedPlan = this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0];
      storage.session.set('goodsForOrder', selectedPlan);
      if (inappTools.isInApp()) {
        native.close();
      } else {
        const url = `https://${document.location.host}/home/order?&ptype=Kr&courseIdx=undefined`;
        window.location.href = url;
      }
    },
    purchasePlan() {
      if (inappTools.isInApp()) {
        // 인앱일 때
        // storage.session.set('routeAfterWebViewClosed', '/app/home/ticket/select/option');
        storage.session.set('routeAfterWebViewClosed', '/app/home/drawer/purchaseMain?tab=basic&isWebView=');
        native.close();
      } else {
        // 웹일 때
        const url = `https://${document.location.host}/v2/plan/all`; // 수강신청 메뉴
        window.location.href = url;
      }
    },
    initBackToPromotionFromCounselingFlag() {
      // 페이지 마운트 되었을 때 프로모션에서 상담예약으로 넘어갔는지 여부를 지워버리는 함수.
      // 만약 이전에 스크롤된 기록이 있다면, 그 위치로 스크롤 넘겨버림.
      const lastScrollOffset = storage.session.get('pr90DaysLastScrollOffset');
      if (lastScrollOffset) {
        setTimeout(() => {
          window.scrollTo(0, lastScrollOffset);
        });
      }
      storage.session.remove('backToPromotionFromCounselingFlag');
      storage.session.remove('pr90DaysLastScrollOffset');
    },

    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1000) {
          this.bottomCtaUp = true;
        } else {
          this.bottomCtaUp = false;
        }
      });
    },

    onChangeTab(idx) {
      this.curTab = idx;
    },
    onTabFixHandlerOn() {
      window.addEventListener('scroll', () => {
        const scrollLocation = document.documentElement.scrollTop;
        const startOffset = document.getElementById('togo1')?.offsetTop;
        // const endOffset = document.getElementById("differ")?.offsetTop;

        if (startOffset) {
          if (inappTools.isInApp()) {
            if (scrollLocation > startOffset - 95) {
              this.tabsFix = true;
              this.tabsFixTop = '48px';
            } else {
              this.tabsFix = false;
            }
          }
          // 웹
          else if (window.innerWidth > 1199) {
            if (scrollLocation > startOffset - 150) {
              this.tabsFix = true;
              this.tabsFixTop = '136px';
            } else {
              this.tabsFix = false;
            }
          }
          // 웹 이하
          else if (scrollLocation > startOffset - 130) {
            this.tabsFix = true;
            this.tabsFixTop = '38px';
          } else {
            this.tabsFix = false;
          }
        }

        const togo1 = document.getElementById('togo1')?.offsetTop;
        const togo2 = document.getElementById('togo2')?.offsetTop;
        const togo3 = document.getElementById('togo3')?.offsetTop;
        const togo4 = document.getElementById('togo4')?.offsetTop;

        let differ = 65;
        if (window.innerWidth > 1199) {
          differ = 140;
        }

        if (togo1 && togo2 && togo3 && togo4) {
          if (togo1 <= scrollLocation + differ && scrollLocation + differ < togo2) {
            this.curTab = 1;
          } else if (togo2 <= scrollLocation + differ && scrollLocation + differ < togo3) {
            this.curTab = 2;
          } else if (togo3 <= scrollLocation + differ && scrollLocation + differ < togo4) {
            this.curTab = 3;
          } else {
            this.curTab = 0;
          }
        }
      });
    },
    guaranteeSectionOffset() {
      if (window.innerWidth > 1199) {
        return 195;
      }
      return 95;
    },
    courseTabChoiceSectionOffset() {
      if (window.innerWidth > 1199) {
        return 140;
      }
      return 65;
    },
    setPurchaeDateInfo() {
      const curDay = new Date();
      const curYear = curDay.getFullYear();
      const curMonth = curDay.getMonth();
      const curDate = curDay.getDate();
      const curDayofWeek = curDay.getDay();
      const weekStartDate = new Date(curYear, curMonth, curDate - curDayofWeek + 1);
      const weekEndDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 1);
      const nextWeekStartDate = new Date(curYear, curMonth, curDate + (6 - curDayofWeek) + 2);
      this.thisWeekStart = `${weekStartDate.getMonth() + 1}/${weekStartDate.getDate()}`;
      this.thisWeekEnd = `${weekEndDate.getMonth() + 1}/${weekEndDate.getDate()}`;
      this.nextWeekStart = `${nextWeekStartDate.getMonth() + 1}/${nextWeekStartDate.getDate()}`;
    },
    showCountdown() {
      return this.nowDate < this.countdownDate;
    },
  },

  computed: {
    hasCouponIdxArray() {
      return (idx) => this.hasCouponIdx.includes(idx);
    },
    hasCouponDownloadAll() {
      return this.hasCouponIdx.length < 4;
    },
    sortNoticeText() {
      return this.promotionData.planGuide.map((data) => data.contents.map((e) => e.text.replace(/\n|n/g, '<br />')));
    },
    appView() {
      return inappTools.isInApp();
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '48px';
      }
      return '0px';
    },
    appBottom() {
      if (inappTools.isInApp()) {
        return '100px';
      }
      return '0px';
    },
    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
  },

  watch: {
    // 옵션 상세 영역에 뿌려줄 선택한 데이터 가공
    choicedOptionIdx() {
      this.choicedOptionData = {
        ...this.promotionData.plan.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0],
      };
    },
  },
};
</script>

<style lang="scss" scoped src="../../assets/newPromotions.scss"></style>
<style lang="scss" scoped>
.promotion {
  overflow: hidden;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider {
  max-width: 275px;
  width: 100%;
  height: 0.8px;
  background: #cecece;
  margin: 0 auto;
}

.label {
  display: flex;
  width: 72px;
  height: 38px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: #000;
  box-sizing: border-box;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.title {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}

.main {
  height: 880px;
  background-color: #0a4622 !important;
  margin: 0 auto;
  position: relative;
  .background_snow {
    padding-top: 24px;
    max-width: 414px;
    width: 100%;
    height: 359px;
    background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_main_bg.png) no-repeat 50%;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
  }
  .tudal {
    height: 404px;
    background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_main_01.png) no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  .tudal_2 {
    max-width: 414px;
    width: 100%;
    margin: 16px auto 0;
    height: 240px;
    /* flex-shrink: 0; */
    background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_main_02.png) no-repeat 100%;
    background-size: cover;
    background-position: center;
  }
  .benefit_container {
    padding: 0 24px;
    margin: -120px auto 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .benefit_box {
      display: flex;
      max-width: 312px;
      width: 100%;
      padding: 30px 34px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      border-radius: 20px;
      background: #fff;
      box-sizing: border-box;
    }

    .title {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .event_date {
      color: rgba(18, 18, 20, 0.6);
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      align-self: stretch;
      margin-top: 8px;
    }

    .benefit {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;

      .benefit_text_wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &.end_text {
          align-items: flex-start !important;
        }
      }

      .benefit_label {
        box-sizing: border-box;
        display: flex;
        width: 48px;
        padding: 6px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 42px;
        border: 1px solid rgba(0, 0, 0, 0.16);
        margin-right: 10px;

        color: rgba(18, 18, 20, 0.6);
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .benefit_text {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */

        .emphasis_text {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }
      .end_text {
        color: #666666;
      }
    }
    .button {
      border-radius: 10px;
      background: #ff1414;
      display: flex;
      padding: 15px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 100%;
    }
    .button.downloadAllAfter {
      background: #afaab7;
      color: #5e5a66;
    }
  }
  .event-explain {
    font-size: 14px;
    color: #ffffff;
    margin-top: 14px;
    text-align: center;
  }
}

.course_container {
  & .course__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #301537;

    & img {
      width: 312px;
      height: 268px;
      margin-bottom: 25px;
    }

    & .course__tab {
      display: flex;
      justify-content: center;
      width: 100%;
      background: #5e2a6b;
      height: 52px;

      & li {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        width: 164px;
        // border-radius: 8px 8px 0 0;
        background: #5e2a6b;

        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        margin: 16.5px 0;
      }

      & li + li {
        border-left: 1px solid rgba(255, 255, 255, 0.15);
      }

      & li.active {
        color: #fff;
        font-weight: 700;
      }
    }
    & .course__tab.fixed {
      position: fixed;
      z-index: 10;
      display: flex;
      // border-bottom: 1px solid #f1f0f5;
      height: 52px;
      background: #5e2a6b;
      vertical-align: middle;

      & li {
        // display: block;
        font-weight: 400;
        font-size: 16px;
        width: 164px;
        color: #959595;
        background-color: #fff;
        border-radius: 0;
        text-align: center;
        background: #5e2a6b;

        color: rgba(255, 255, 255, 0.5);
        vertical-align: middle;
        margin: 16.5px 0;
      }

      & li + li {
        border-left: 1px solid rgba(255, 255, 255, 0.15);
      }

      & li.active {
        /* background-color: #000000; */
        color: #fff;
        font-weight: 700;
      }
    }
  }

  & .coupon__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 38px 24px 44px;
    text-align: center;
    .coupon {
      width: 258px;
      height: 129px;
      margin-bottom: 8px;
      cursor: pointer;
    }
    .coupon.coupon200000 {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_20_on.png) no-repeat 50%;
      background-size: contain;
    }
    .coupon.coupon200000.is-download {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_20_off.png) no-repeat 50%;
      background-size: contain;
    }
    .coupon.coupon100000 {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_10_on.png) no-repeat 50%;
      background-size: contain;
    }
    .coupon.coupon100000.is-download {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_10_off.png) no-repeat 50%;
      background-size: contain;
    }
    .coupon.coupon70000 {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_7_on.png) no-repeat 50%;
      background-size: contain;
    }

    .coupon.coupon70000.is-download {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_7_off.png) no-repeat 50%;
      background-size: contain;
    }
    .coupon.coupon50000 {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_5_on.png) no-repeat 50%;
      background-size: contain;
    }
    .coupon.coupon50000.is-download {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_5_off.png) no-repeat 50%;
      background-size: contain;
    }
    .coupon.coupon30000 {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_3_on.png) no-repeat 50%;
      background-size: contain;
    }
    .coupon.coupon30000.is-download {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_3_off.png) no-repeat 50%;
      background-size: contain;
    }
    /* .coupon.coupon15000 {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_3_on.png) no-repeat 50%;
      background-size: contain;
    }
    .coupon.coupon15000.is-download {
      background: url(//res.tutoring.co.kr/res/images/evt/202412/2024festival/festival_coupon_3_on.png) no-repeat 50%;
      background-size: contain;
    } */
    button.downloadAllBefore {
      max-width: 312px;
      width: 100%;
      height: 56px;

      background: #ff1414;
      border-radius: 10px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      margin-top: 32px;
      font-weight: 700;
    }
    button.downloadAllAfter {
      max-width: 312px;
      width: 100%;
      height: 56px;
      background: #cccccc;
      border-radius: 10px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      margin-top: 40px;
      font-weight: 700;
    }
  }

  & .coupon__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    padding: 32px 44px 49px;
    text-align: left;
    word-break: keep-all;

    .inner {
      align-items: flex-start;
      width: 100%;
      max-width: 270px;
    }

    .title {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      margin-bottom: 5px;
    }
    .list__item {
      position: relative;
      color: #666666;
      font-size: 12px;
      line-height: 16px;
      &:before {
        content: '\B7';
        position: absolute;
        top: 0;
        left: -7px;
        color: #666;
      }
    }
  }

  & .course__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 24px;

    .inner {
      flex-direction: column;
      align-items: center;

      width: 100%;

      & img.big_receipt {
        max-width: 360px;
        width: 100%;
        height: auto;
      }
      & img.festival_03 {
        max-width: 360px;
        width: 100%;
        height: auto;
      }

      & button.buyCourse {
        display: flex;
        max-width: 312px;
        width: 100%;
        height: 56px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 10px;
        background: #ff1414;

        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin: 40px 0 0;
      }
      // 경품이벤트
      & img.togo_title04 {
        width: 197px;
        height: 110px;
        margin-bottom: 30px;
      }
      & img.togo_04_1 {
        width: 214x;
        height: 57px;
        margin-bottom: 30px;
      }
      & img.togo_04_2 {
        width: 214x;
        height: 57px;
        margin-bottom: 30px;
      }
      & img.togo_04_3 {
        width: 180px;
        height: 75px;
        margin-bottom: 50px;
      }
      & img.togo_04_4 {
        //경품
        width: 288px;
        height: 219px;
        margin-bottom: 50px;
      }
      & img.togo_04_5 {
        width: 208px;
        height: 250px;
        margin-bottom: 30px;
      }
      & img.togo_04_6 {
        width: 208px;
        height: 240px;
        margin-bottom: 30px;
      }
      & img.togo_04_7 {
        width: 208px;
        height: 250px;
        margin-bottom: 30px;
      }
    }
  }
  & .course__info.blue {
    background-color: #301537;
  }
  & .course__info.black {
    background-color: #222222;
  }
  & .course__info.dark {
    background-color: #000000;
    & .inner {
      padding: 50px 0;
    }
    & .promotion-guide {
      margin: 0 auto;
      .heading {
        font-weight: 700;
        font-size: 14px;
        color: #3e83fe;
      }
      .list__item {
        position: relative;
        color: #636363;
        font-size: 12px;
        line-height: 22px;
        &:before {
          content: '\B7';
          position: absolute;
          top: 0;
          left: -7px;
          color: #666;
        }
      }
    }
  }

  & .course__special {
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 60px 0;
    & .title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 0;
    }
    & .course__slide__wrapper {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      overflow: hidden;
      margin-bottom: 30px;
      min-width: 1286px;
      & .course__slide {
        width: 1286px;
        min-width: 1286px;

        & .slide__card {
          background-color: #fefefe;
          width: 308px;
          min-width: 308px;
          height: 128px;
          cursor: pointer;
          border-radius: 20px;
          overflow: hidden;
          box-sizing: border-box;
          @media only screen and (max-width: 768px) {
            -webkit-box-sizing: border-box;
          }

          & img {
            width: 100%;
          }
        }
      }
    }

    button {
      display: flex;
      align-items: center;
    }

    button .showAllCourse {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-decoration-line: underline;
      color: #9f9f9f;
      margin-right: 5px;
    }
    button .togo_02_2 {
      width: 34px;
      height: 34px;
    }
  }
}

/* 구매하기 cta */
.challenge-cta {
  position: fixed;
  bottom: -100%;
  right: 0;
  left: 0;
  z-index: 50;
  transition: bottom 0.4s;
  @media (max-width: 1199px) {
    & {
      bottom: 0;
    }
  }

  &.ani-bottom-up {
    bottom: 0;
  }

  & .challenge-cta__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(6px);
    height: 84px;
    // -webkit-filter: blur(5px);

    @media (max-width: 1199px) {
      & {
        height: 70px;
        padding: 0 20px;
      }
    }
    &.sheetOn {
      background: #fff;
    }
    & .wrapper {
      width: 100%;
      max-width: 672px;

      & button {
        display: flex;
        width: 100%;
        height: 50px;
        background: #ff6668;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: -1px;
        @media (max-width: 1199px) {
          & {
            font-size: 15px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}

// 하단 버튼 작업
.challenge-cta.is-info {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  color: #000;
  transition: bottom 0.4s;
}

.challenge-cta.is-info .challenge-cta__wrap {
  border-top: none;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .heading {
  display: inline-flex;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  color: #999999;
  margin-right: 5px;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .date {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #ff1212;
  color: #ffffff;
  padding: 5px 13px;
  border-radius: 20px;
  margin-bottom: 10px;
  line-height: 14px;
  font-size: 11px;
  font-weight: 700;
}

.promotion .challenge-cta .challenge-cta__wrap .wrapper .textwrap .time {
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  color: #000;
}

/* 구매하기 cta */
.promotion .challenge-cta .challenge-cta__wrap {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
}

.promotion .challenge-cta .challenge-cta__wrap .date {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #ff1212;
  color: #ffffff;
  padding: 0 17px;
}

/* 데스크톱 */
.promotion .challenge-cta.is-info .cta__button {
  height: 44px;
  display: flex;
  border-radius: 10px;
  background: #ff1414;
  border-radius: 5px;
  color: #fff;
  align-items: center;
  justify-content: center;
  // max-width: 500px;
  width: 100%;
  margin: auto;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
}
.promotion .challenge-cta.is-info .cta__button .btnText {
  display: inline-block;
  vertical-align: middle;
  margin-right: 9px;
}
.promotion .challenge-cta.is-info .cta__button .rightArrow {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/icon_right.png) no-repeat 50%;
  background-size: contain;
  vertical-align: middle;
}

@media (max-width: 1199px) {
  .promotion .challenge-cta.is-info .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .promotion .challenge-cta.is-info .wrapper .textwrap {
    width: 25%;
    margin-right: 20px;
  }
  .promotion .challenge-cta.is-info .wrapper .btnwrap {
    max-width: 323px;
    width: 100%;
  }
  .promotion .challenge-cta.is-info .wrapper .btnwrap .cta__mobile {
    max-width: 100%;
  }
}

@media (min-width: 1199px) {
  .promotion .challenge-cta.is-info .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .promotion .challenge-cta.is-info .wrapper .textwrap {
    width: 25%;
    margin-right: 20px;
  }
  .promotion .challenge-cta.is-info .wrapper .btnwrap {
    width: 100%;
  }
  .promotion .challenge-cta.is-info .wrapper .btnwrap .cta__mobile {
    max-width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .promotion .challenge-cta.is-info .wrapper .textwrap {
    margin-right: 10px;
    width: 60%;
  }
  .promotion .challenge-cta.is-info .cta__button {
    margin-top: 0;
    height: 44px;
    font-size: 15px;
    max-width: 100%;
    border-radius: 10px;
    background: #ff1414;
  }
}
.list__item__text {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.promotion-notice .notice__item .notice .notice__list .list__item {
  position: relative;
  color: #636363;
  font-size: 12px;
  line-height: 20px;
  text-indent: 0;
  border: 1px solid black;

  &:before {
    content: '\B7';
    position: absolute;
    top: 0;
    left: -7px;
    color: #666;
  }
}

@media (min-width: 512px) and (max-width: 767px) {
  .banner__main {
    & img {
      padding-top: 20px;
      min-width: 400px;
      /* width: 100vw; */
      padding-bottom: 80px;
    }
    & button {
      position: absolute;
      bottom: 12%;
      min-width: 260px;
      width: calc(40vw * 1.4);
      max-width: 430px;
      height: 45px;
      background: #fff852;
      border-radius: 10px;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #000000;
      font-weight: 700;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .course_container .course__title .inner {
    padding: 25px;
  }
  /* .course_container .course__info .inner {
    width: 100vw;
    height: calc(0.98 * 100vw);
  } */
  .solutions {
    justify-content: space-between;
  }
  .solutions .inner .solution img.togo_list01 {
    width: 100vw;
    height: calc(1.5 * 100vw);
  }
  .solutions .inner .solution img.togo_list02 {
    width: 100vw;
    height: calc(1.12 * 100vw);
  }
  .solutions .inner .solution img.togo_list03 {
    width: 100vw;
    height: calc(1.05 * 100vw);
  }
  .solutions .inner .solution img.togo_list04 {
    width: 100vw;
    height: calc(1.12 * 100vw);
  }
  .solution_btn {
    width: calc(0.95 * 100vw);
    margin: auto;
  }
}
.benefit__desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 16px 0;
}
.pink_text {
  color: #ab72fd;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 19px;
}
.sample_btn {
  justify-content: center;
  max-width: 300px;
  width: 100%;
  display: inline-flex;
  height: 45px;
  align-items: center;
  gap: 62px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #000;
  position: relative;
}
.sample_btn p {
  color: #fff;
  text-align: center;
  font-size: 12.857px;
  font-style: normal;
  font-weight: 300;
  line-height: 12.857px;
}
.certificate-sector {
  padding: 60px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eee;
  width: 100%;
}
.title {
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 166.667% */
  letter-spacing: -0.5px;
}
.content {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  & .content__list {
    & .list__item {
      position: relative;
      padding-left: 7px;
      word-break: keep-all;
      &:before {
        content: '\B7';
        position: absolute;
        top: 0;
        left: 0;
        color: #666;
      }
    }
  }
}
.promotion_contents {
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px 42px 25px;
}
.contents-center {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
}
.rArrow {
  position: absolute;
  right: 20px;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(//res.tutoring.co.kr/res/images/evt/202206/readytogo/icon_right.png) no-repeat 50%;
  background-size: contain;
  vertical-align: middle;
}
</style>
